import { transformPopups } from '@activebrands/core-web/libs/storyblok/utils/transform-popups';
import { graphql, useStaticQuery } from 'gatsby';

const useSiteQuery = () => {
    const { application } = useStaticQuery(
        graphql`
            {
                application {
                    data {
                        config {
                            siteConfig {
                                popups {
                                    _uid
                                    content
                                    cookieLifespan
                                    timeOut
                                    visibilitySettings
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const content = application.data.config.siteConfig || {};

    return {
        popups: content.popups?.length > 0 ? transformPopups(content.popups) : [],
    };
};

export default useSiteQuery;
